import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { MyHttpApi, PrivilegeItem, ProductCategory, ProductSubCategoryUpdateRequest, StorageUnit } from 'utils/api';
import { getSelectedCorporation, privilegeItemFromElement, privilegeToTypeAndName } from 'utils/corporation-util';

@autoinject
export class PosProductSubCategoryEdit {
  private productSubCategory: ProductSubCategoryUpdateRequest = {
    name: "",
    productCategoryId: "",
    type: "CLIENT",
    delete: false,
  };
  private privilege?: PrivilegeItem;
  private isMaster = false;
  private productCategoryList: ProductCategory[] = [];
  private storageUnitList: StorageUnit[] = [];
  private typeAndName = "";
  private disabled = false;
  private extraStorageUnit?: StorageUnit = undefined;

  constructor(private api: MyHttpApi, private router: Router, private i18n: I18N) {
  }

  async activate(params: { id?: string, isMaster?: string; }) {
    this.isMaster = params.isMaster === "true";
    if (params.id) {
      let productSubCategory = await this.api.productSubCategoryById({ id: params.id });
      this.productSubCategory = { ...productSubCategory, delete: !!productSubCategory.deleteTime, type: 'CLIENT' };
      this.privilege = privilegeItemFromElement(productSubCategory);
    }
    if (!this.isMaster && !params.id) {
      this.privilege = getSelectedCorporation();
    } else if (this.isMaster) {
      this.privilege = undefined;
    }

    if (this.privilege) {
      let canEdit = await this.api.privilegeCanEdit(this.privilege);
      if (this.privilege.id && this.privilege.type === 'CLIENT') {
        const bgr = await this.api.businessGroupRestrictionsByClientId({ clientId: this.privilege.id });
        canEdit = bgr.clientSpecificSubCategories;
      }
      this.disabled = !canEdit;
    }

    this.typeAndName = await privilegeToTypeAndName(this.api, this.i18n, this.privilege);
    
    const [productCategoryList, storageUnitList] = await Promise.all([
      this.api.productCategoryList({ type: this.privilege?.type || 'CLIENT', id: this.privilege?.id }),
      this.api.storageUnitList({ type: this.privilege?.type || 'CLIENT', id: this.privilege?.id }),
    ]);
    this.extraStorageUnit = storageUnitList.find(x => x.id === this.productSubCategory.defaultStorageUnitId);
    this.productCategoryList = productCategoryList.filter(x => !x.deleteTime && !x.supersededById);
    this.storageUnitList = storageUnitList.filter(x => !x.deleteTime && !x.supersededById);
  }

  @computedFrom("disabled")
  get disabledText() {
    if (this.disabled) {
      return this.i18n.tr('businessGroupRestriction.readOnly');
    }
    return "";
  }

  @computedFrom("productSubCategory.defaultBaseUnit")
  get dynamicStorageUnitList() {
    return this.storageUnitList.filter(x => !this.productSubCategory.defaultBaseUnit || x.baseUnit === this.productSubCategory.defaultBaseUnit);
  }

  @computedFrom("productCategory.id")
  get productCategoryName() {
    return this.productCategoryList.find(x => x.id === this.productSubCategory.productCategoryId)?.name;
  }

  async save(deleted: boolean) {
    await this.api.productSubCategoryUpdate({
      ...this.productSubCategory,
      privilegeId: this.privilege?.id,
      type: this.privilege?.type || 'CLIENT',
      delete: deleted,
    });
    this.router.navigateBack();
  }
}
